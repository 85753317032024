import styled from "styled-components";
import { Color as Colors, Color } from "../../../src/utils/Color";
import CustomFont from "../../CustomFont";
import * as blogSelectors from "../../../src/selectors/blog";
import Image from "next/image";
import _ from "lodash";
import Button from "@mui/material/Button";
import Hidden from "@material-ui/core/Hidden";
import { getBlogDetailPublistedAtTimestamp } from "../../../src/selectors/blog";
import moment from "moment";

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  //box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 4px;
  height: 100%;
  overflow: hidden;
  // border: 1px solid ${Colors.lightGrey};
`;

const StyledMobileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // box-shadow: ${props => (props.isHideShadow ? "none" : "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px")};
  border-radius: 4px;
  height: 100%;
  overflow: hidden;
  // border: 1px solid ${props => (props.isHideShadow ? Colors.lightGrey : "transparent")};
  // border: 1px solid ${Colors.lightGrey};
`;

const StyledMobileImageContainer = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  min-height: 200px;
  border-bottom: 1px solid ${props => (props.isHideShadow ? Colors.lightGrey : "transparent")};
`;

const StyledMobileContentContainer = styled.div`
  //padding: 20px 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
  // background: ${Colors.textWhite};
  //min-height: 150px;
  margin-top: 15px;
`;

const StyledLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  //padding: 30px 30px;
  // background: ${Colors.textWhite};
  margin-top: 15px;

`;
const StyledRightContainer = styled.div`
  display: flex;
  position: relative;
  min-height: 200px;
  height: auto;
  width: 100%;
  //margin-left: 30px;
`;

const BlogCardItem = ({ item, onClickItem, mobileView = false, isHideShadow = false }) => {
  const id = blogSelectors.getBlogID(item);
  const slug = blogSelectors.getBlogDetailSlug(item);
  const categoryName = blogSelectors.getBlogCategoryName(item);
  const title = blogSelectors.getBlogTitle(item);

  const brief = blogSelectors.getBlogDetailBrief(item);
  const image = blogSelectors.getBlogDetailImageMediumSrc(item);
  const timestamp = blogSelectors.getBlogDetailPublistedAtTimestamp(item);
  const dateNow = new Date(timestamp * 1000);
  const date = moment.unix(timestamp).format("YYYY-MM-DD");
  return (
    <a
      href={`${process.env.WEBSITE_URL}insider-guide/${slug}`}
      style={{ cursor: "pointer" }}
      onClick={() => onClickItem(slug)}
    >
      {mobileView ? (
        <Hidden smDown>
          <StyledMobileContainer isHideShadow={isHideShadow}>
            <a href={`${process.env.WEBSITE_URL}insider-guide/${slug}`}>
              <StyledMobileImageContainer isHideShadow={isHideShadow}>
                <Image
                  objectFit="cover"
                  loader={() => (!_.isEmpty(image) ? image : "/placeholderBanner.png")}
                  src={!_.isEmpty(image) ? image : "/placeholderBanner.png"}
                  layout="fill"
                  alt={title}
                  placeholder="blur"
                  blurDataURL={image}
                />
              </StyledMobileImageContainer>
            </a>
            <StyledMobileContentContainer>
              <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                {/*<a href={`${process.env.WEBSITE_URL}insider-guide/${slug}`} onClick={() => onClickItem(slug)} style={{display: "flex", flexDirection: "column", wordWrap: "break-word"}}>*/}
                  <CustomFont
                    size="normal"
                    variant="h3"
                    styles={{
                      fontWeight: "600",
                      color: Colors.blackColor,
                      overflow: "hidden",
                      /* width: 325px; */
                      display: "-webkit-box",
                      "-webkit-line-clamp": "1",
                      "-webkit-box-orient": "vertical"
                    }}
                  >
                    {title}
                  </CustomFont>
                {/*</a>*/}
                <CustomFont
                    size="xsmall"
                  styles={{
                    color: Colors.blackColor,
                    marginTop: 10,
                    overflow: "hidden",
                    /* width: 325px; */
                    display: "-webkit-box",
                    "-webkit-line-clamp": "1",
                    "-webkit-box-orient": "vertical",
                    minHeight: "20px"
                  }}
                >
                  {brief}
                </CustomFont>
              </div>
              <CustomFont size="xsmall" styles={{ color: Colors.EnhanceIconColor, marginTop: 15 }}>
                {date}
              </CustomFont>
              {/*<div>*/}
              {/*  <Button*/}
              {/*    color="primary"*/}
              {/*    style={{*/}
              {/*      padding: "10px 20px",*/}
              {/*      borderRadius: "4px",*/}
              {/*      minWidth: "auto",*/}
              {/*      backgroundColor: "white",*/}
              {/*      border: "1px solid #2c2c2c",*/}
              {/*      marginTop: 25*/}
              {/*    }}*/}
              {/*    onClick={() => onClickItem([type])}*/}
              {/*    variant="contained"*/}
              {/*  >*/}
              {/*    <CustomFont styles={{ fontWeight: "600" }}>Read More</CustomFont>*/}
              {/*  </Button>*/}
              {/*</div>*/}
            </StyledMobileContentContainer>
          </StyledMobileContainer>
        </Hidden>
      ) : (
        <Hidden smDown>
          <StyledContainer>
            <StyledLeftContainer>
              {/*<a href={`${process.env.WEBSITE_URL}insider-guide/${slug}`} onClick={() => onClickItem(slug)}>*/}
                <CustomFont size="normal" variant="h3" styles={{ fontWeight: "600", color: Colors.blackColor, overflow: "hidden",
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical" }}>
                  {title}
                </CustomFont>
              {/*</a>*/}
              <CustomFont
                size="xsmall"
                styles={{
                  color: Colors.blackColor,
                  marginTop: 10,
                  overflow: "hidden",
                  /* width: 325px; */
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical",
                  minHeight: "20px"
                }}
              >
                {brief}
              </CustomFont>
              <CustomFont size="xsmall" styles={{ color: Colors.EnhanceIconColor, marginTop: 15 }}>{date}</CustomFont>
              {/*<div>*/}
              {/*  <Button*/}
              {/*    color="primary"*/}
              {/*    style={{*/}
              {/*      padding: "10px 20px",*/}
              {/*      borderRadius: "4px",*/}
              {/*      minWidth: "auto",*/}
              {/*      backgroundColor: "white",*/}
              {/*      marginTop: 30,*/}
              {/*      border: "1px solid #2c2c2c"*/}
              {/*    }}*/}
              {/*    onClick={() => onClickItem([type])}*/}
              {/*    variant="contained"*/}
              {/*  >*/}
              {/*    <CustomFont styles={{ fontWeight: "600" }}>Read More</CustomFont>*/}
              {/*  </Button>*/}
              {/*</div>*/}
            </StyledLeftContainer>
            <StyledRightContainer>
              <a href={`${process.env.WEBSITE_URL}insider-guide/${slug}`}>
                <Image
                  objectFit="cover"
                  loader={() => (!_.isEmpty(image) ? image : "/placeholderBanner.png")}
                  src={!_.isEmpty(image) ? image : "/placeholderBanner.png"}
                  layout="fill"
                  alt={title}
                />
              </a>
            </StyledRightContainer>
          </StyledContainer>
        </Hidden>
      )}
      {/*========Desktop Version======*/}
      <Hidden mdUp>
        <StyledMobileContainer isHideShadow={isHideShadow}>
          <StyledMobileImageContainer>
            <a href={`${process.env.WEBSITE_URL}insider-guide/${slug}`}>
              <Image
                objectFit="cover"
                loader={() => (!_.isEmpty(image) ? image : "/placeholderBanner.png")}
                src={!_.isEmpty(image) ? image : "/placeholderBanner.png"}
                layout="fill"
                alt={title}
              />
            </a>
          </StyledMobileImageContainer>
          <StyledMobileContentContainer>
            {/*<a href={`${process.env.WEBSITE_URL}insider-guide/${slug}`}>*/}
              <CustomFont
                size="normal"
                variant= "h3"
                styles={{
                  fontWeight: "600",
                  color: Colors.blackColor,
                  overflow: "hidden",
                  /* width: 325px; */
                  display: "-webkit-box",
                  "-webkit-line-clamp": "1",
                  "-webkit-box-orient": "vertical"
                }}
              >
                {title}
              </CustomFont>
            {/*</a>*/}
            <CustomFont
              styles={{
                color: Colors.blackColor,
                marginTop: 10,
                overflow: "hidden",
                /* width: 325px; */
                display: "-webkit-box",
                "-webkit-line-clamp": "1",
                "-webkit-box-orient": "vertical"
              }}
              size="xsmall"
            >
              {brief}
            </CustomFont>
            <CustomFont size="xsmall" styles={{ color: Colors.EnhanceIconColor, marginTop: 15 }}>
              {date}
            </CustomFont>
            {/*<div>*/}
            {/*  <Button*/}
            {/*    color="primary"*/}
            {/*    style={{*/}
            {/*      padding: "10px 20px",*/}
            {/*      borderRadius: "4px",*/}
            {/*      minWidth: "auto",*/}
            {/*      backgroundColor: "white",*/}
            {/*      border: "1px solid #2c2c2c",*/}
            {/*      marginTop: 25*/}
            {/*    }}*/}
            {/*    onClick={() => onClickItem([type])}*/}
            {/*    variant="contained"*/}
            {/*  >*/}
            {/*    <CustomFont styles={{ fontWeight: "600" }}>Read More</CustomFont>*/}
            {/*  </Button>*/}
            {/*</div>*/}
          </StyledMobileContentContainer>
        </StyledMobileContainer>
      </Hidden>
    </a>
  );
};

export default BlogCardItem;

const parallaxStyle = (theme) => ({
  parallax: {
    height: "100vh",
    maxHeight: "1000px",
  //  overflow: "hidden",
    position: "relative",
    backgroundPosition: "center top",
    backgroundSize: "cover",
    margin: "0",
    padding: "0",
    border: "0",
    display: "flex",
    alignItems: "center",
   // zIndex:"99999"
  },
  filter: {
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)",
    },
    "&:after,&:before": {
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
    },
  },
  small: {
    height: "380px",
  },
  parallaxResponsive: {
    [theme.breakpoints.down("md")]: {
    },
  },
});

export default parallaxStyle;

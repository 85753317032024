import React, { PureComponent } from "react";
import styled from "styled-components";
import Image from "next/image";
import CustomFont from "../../components/CustomFont";
import { Color } from "../../src/utils/Color";
import * as projectSelectors from "../../src/selectors/project";
import _ from "lodash";
import Helpers from "../../src/utils/Helpers";
import * as newsSelectors from "../../src/selectors/news";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import { getProjectDetailTypeCode } from "../../src/selectors/project";
import { maxDevice } from "../../src/utils/breakpoints";

const ProjectContainer = styled.a`
  width: 100%;
  position: relative;

  &:hover {
    cursor: pointer;
  }
`;

const ProjectDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const ProjectDetailStaticContainer = styled.div`
  padding: 3px 15px;
  border-radius: 4px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const ProjectDetailItemContainer = styled.div`
  padding: 3px 15px;
  border-radius: 4px;
  margin-right: 10px;
  margin-top: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 10px;
  left: 0px;
`;

const ImageContainer = styled.div`
  height: 220px;
  width: 100%;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  @media ${maxDevice.md} {
    height: 190px;
  }
`;

const TitleContainer = styled.div`
  display: inline-block;
  box-sizing: border-box;
  white-space: pre-line;
  word-wrap: break-word;
  margin-top: 10px;
  max-width: 100%;
`;

const TitleTextContainer = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-size: 1rem;
  font-weight: 600;
  //min-height: 48px;
  color: ${Color.blackColor};
`;

const DescTextContainer = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-size: 0.8rem;
  //margin-left: 5px;
  color: ${Color.blackColor};
`;

const DescContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const laberColor = [
  {
    title: "Residential",
    lightColor: "#FFD8D2",
    color: "#D93F2A"
  },
  {
    title: "Industrial",
    lightColor: "#D5CAF3",
    color: "#7951EA"
  },
  {
    title: "Commercial",
    lightColor: "#C8D4F4",
    color: "#3462F4"
  }
];

class HomeProjectItem extends PureComponent {
  onClickItem = e => {
    e.preventDefault();
    const { onClickItem, item } = this.props;
    const id = projectSelectors.getProjectDetailSlug(item);

    onClickItem && onClickItem(id);
  };
  render() {
    const { item } = this.props;

    const image = projectSelectors.getProjectDetailImageMediumSrc(item);
    const kind = projectSelectors.getProjectDetailKindName(item);
    const name = projectSelectors.getProjectDetailName(item);
    const category = projectSelectors.getProjectDetailCategoryName(item);
    const cityName = projectSelectors.getProjectDetailCityName(item);
    const stateName = projectSelectors.getProjectDetailStateName(item);
    const type = projectSelectors.getProjectDetailTypeName(item);
    const hostname = Helpers.isProduction
      ? typeof window !== "undefined" && window.location.hostname
        ? window.location.hostname
        : ""
      : "localhost:3000";
    const http = Helpers.isProduction ? "https" : "http";
    const slug = projectSelectors.getProjectDetailSlug(item);
    const developer = projectSelectors.getProjectDetailPublisherName(item);

    const propertyLabelColor = _.find(Helpers.laberColor(), item => {
      const propertyTitle = _.get(item, ["title"], "");
      return _.isEqual(type, propertyTitle);
    });
    const propertyCurrentLabelColor = _.get(propertyLabelColor, ["color"], "");

    return (
      <ProjectContainer href={`${process.env.WEBSITE_URL}project/${slug}`} onClick={this.onClickItem}>
        <div>
          <ImageContainer>
            <Image
              src={image}
              loader={() => image}
              layout="fill"
              style={{ borderRadius: 4 }}
              objectFit="cover"
              alt={name}
              placeholder="blur"
              blurDataURL={image}
            />
            <ProjectDetailItemContainer>
              <CustomFont size="small" styles={{ color: Color.textWhite, fontWeight: "600" }}>
                {category}
              </CustomFont>
            </ProjectDetailItemContainer>
          </ImageContainer>
          <ProjectDetailContainer>
            <ProjectDetailStaticContainer
                style={{ backgroundColor: "white", border: `1px solid ${propertyCurrentLabelColor}` }}
            >
              <CustomFont size="small" styles={{ color: propertyCurrentLabelColor, fontWeight: "600" }}>
                {type}
              </CustomFont>
            </ProjectDetailStaticContainer>
          </ProjectDetailContainer>
        </div>
        <TitleContainer style={{}}>
          <TitleTextContainer><CustomFont variant="h3" size="normal" styles={{ fontWeight: "600" }} >{name}</CustomFont></TitleTextContainer>
          <DescContainer>
            {/*<LocationOnOutlinedIcon style={{ fontSize: "0.8rem", marginTop: "3px",color: Color.blackColor }} />*/}
            <DescTextContainer> <CustomFont size="xsmall" >{cityName}, {stateName}</CustomFont></DescTextContainer>
          </DescContainer>
          {!_.isEmpty(developer) ? (
            <DescContainer>
              {/*<PsychologyOutlinedIcon style={{ fontSize: "0.8rem", marginTop: "3px",color: Color.blackColor }} />*/}
              {/*<DescTextContainer>*/}
              {/*  <CustomFont size="xsmall" >Developer: {developer}</CustomFont>*/}
              {/*  /!*{`Developer : ${developer}`}*!/*/}
              {/*</DescTextContainer>  */}
              <DescTextContainer>
                <CustomFont size="xsmall" >{developer}</CustomFont>
                {/*{`Developer : ${developer}`}*/}
              </DescTextContainer>
            </DescContainer>
          ) : (
            false
          )}
        </TitleContainer>
        {/*<TitleContainer style={{}}>*/}
        {/*  <DescTextContainer>*/}
        {/*    {`${cityName}, ${stateName}`}*/}
        {/*  </DescTextContainer>*/}
        {/*</TitleContainer>*/}
      </ProjectContainer>
    );
  }
}

export default HomeProjectItem;

import React, { PureComponent } from "react";
import styled from "styled-components";
import Parallax from "../../Parallax/Parallax";
import { withTheme } from "styled-components";
import Container from "@mui/material/Container";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { buttonUnstyledClasses } from "@mui/base";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import { Color as Colors, Color } from '../../../src/utils/Color'
import CustomFont from "../../../components/CustomFont";
import InfoIcon from "@mui/icons-material/Info";
import LoadingOverlay from "../../LoadingOverlay";
import _ from "lodash";
import SearchIcon from "@material-ui/icons/Search";
import PlacesAutocomplete from "react-places-autocomplete";
import Skeleton from '@mui/material/Skeleton'
import Constants from "../../../src/constants";
import Router from "next/router";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1 // optional, default to 1.
  }
};

const Tab = styled(TabUnstyled)`
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  //background-color: rgba(0, 0, 0, 0.6);
  background: transparent;
  padding: 10px 25px;
  border: none;
  border-radius: 4px 0 0 0;
  display: flex;
  justify-content: center;
  width: fit-content;
  transition: border-bottom 0.5s ease;
  border-bottom: 2px solid transparent;
  margin-bottom: 10px;

  &:hover {
    //background-color: white;
    color: #295ffe;
  }

  &:focus {
    color: #fff;
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    //background-color: white;
    //color: #295ffe;  
    color: ${Colors.textWhite};
    border-bottom: 2px solid white;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabRight = styled(TabUnstyled)`
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  //background-color: rgba(0, 0, 0, 0.6);
  background-color: transparent;

  padding: 10px 25px;
  border: none;
  border-radius: 0 4px 0 0;
  display: flex;
  justify-content: center;
  width: fit-content;
  transition: border-bottom 0.5s ease;
  border-bottom: 2px solid transparent;
  margin-bottom: 10px;


  &:hover {
    //background-color: white;
    color: #295ffe;
  }

  &:focus {
    color: #fff;
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    //background-color: white;
    //color: #295ffe;
    color: ${Colors.textWhite};
    border-bottom: 2px solid white;
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)`
  width: auto;
  background-color: transparent;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;
const HeaderImageStyle = styled(Parallax)`
  height: auto !important;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  z-index: 8!important;
  position: relative;
`;

const BrandStyle = styled.div`
  color: #fff;
  text-align: left;
`;

const TitleStyle = styled(Typography)`
  font-size: 2.2rem;
  font-weight: 600;
  display: inline-block;
  position: relative;
`;

const ContainerStyle = styled.div`
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;
const TextFieldContainer = styled(TextField)`
  background-color: white;
  width: 100%;
  & .MuiFilledInput-root {
    background-color: white;

    &:before {
      border-bottom: 0 !important;
    }
    &:hover {
      outline: 0 !important;
      background-color: white;
    }
    &:focus {
      outline: 0 !important;
    }
  }
  & .Mui-focused {
    outline: 0 !important;
    border-bottom: 0px;
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: white;
    }
    &:hover fieldset {
      border-color: white;
    }
    &.Mui-focused fieldset {
      border-color: white;
    }
  }
  & input::placeholder {
    font-size: 13px; // 
  }
`;

const AutoCompleteContainer = styled.div`
  width: 100%;
  min-height: auto;
  height: auto;
  max-height: 300px;
  overflow: auto;
  background: white;
  position: absolute;
  border-radius: 0 0 4px 4px;
  box-shadow: 1px 1px 5px #aaaaaa;
  
  padding-top: 15px;
`;

const SearchItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
`;

class MobileBannerContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEL: null,
      buyVisible: false,
      tab: 0,
      rentVisible: false,
      focus: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.focus !== this.props.focus) {
      if (!this.props.focus) {
        this.handleClose();
        this.handleRentClose();
      }
    }
  }

  handleClose = e => {
    const { handleClose } = this.props;

    this.setState({ buyVisible: false, anchorEL: null });
  };

  handleRentClose = e => {
    const { handleClose } = this.props;

    // this.setState({ rentVisible: false, anchorEL: null });
    this.setState({ rentVisible: false, anchorEL: null });
  };

  onFocus = e => {
    const { onClickFocus } = this.props;
    onClickFocus && onClickFocus();
    e.stopPropagation();

    this.setState({ buyVisible: true });
  };

  onRentFocus = e => {
    const { onClickFocus } = this.props;
    onClickFocus && onClickFocus();

    e.stopPropagation();

    this.setState({ focus: true }, () => this.setState({ rentVisible: true }));
  };

  onChangeBuyText = e => {
    const { onChangeBuyText } = this.props;

    onChangeBuyText && onChangeBuyText(e);
  };
  onChangeRentText = e => {
    const { onChangeRentText } = this.props;

    onChangeRentText && onChangeRentText(e);
  };

  onChangeTab = (e, value) => {
    this.setState({ tab: value, buyVisible: false });
  };

  onClose = e => {
    e.preventDefault();

    if (this.props.focus) {
      this.setState({ rentVisible: false, buyVisible: false, focus: false });
    }
  };
  onClickBuySearch = e => {
    const { onClickBuySearch } = this.props;

    onClickBuySearch && onClickBuySearch();
  };

  onClickRentSearch = e => {
    const { onClickRentSearch } = this.props;

    onClickRentSearch && onClickRentSearch();
  };

  onClickItem = (e, title) => {
    e.preventDefault();
    e.stopPropagation();

    const { onClickItem } = this.props;
    onClickItem && onClickItem(title);
    //this.setState({ buyVisible: true })
  };
  onClickRentItem = (e, title) => {
    e.preventDefault();
    e.stopPropagation();

    const { onClickRentItem } = this.props;
    onClickRentItem && onClickRentItem(title);
    //this.setState({ buyVisible: true })
  };

  getFormattedStateData = () => {
    const {commonStateData} =this.props
    const {rentSearchText, buySearchText} = this.state

    if(_.isEmpty(rentSearchText)){
      return commonStateData
    }else {
      const formattedSearchValue = _.replace(rentSearchText,'/[\\s,]/g').toLowerCase()
      return _.filter(commonStateData,item=>{

        const  formattedName = _.replace(item.name,'').toLowerCase()
        return _.includes(formattedName,formattedSearchValue)
      })
    }
  }

  getRoute = () => {
    return Constants.SECTION_PROPERTY_SALE;
  };

  onCloseAutoCompleted = e => {
    // e.stopPropagation();
    this.setState({ isShowAutoCompleted: false });
  };

  handleSelectLocal = (e, item) => {
    e.stopPropagation();
    const { debounceSearchTrigger } = this.state;
    const ID = _.get(item, ["id"], null);

    const name = _.get(item, ["name"], null);
    const radius = _.get(item, ["radius"], null);
    const coordinateStateLat = _.get(item, ["coordinates", "latitude"], null);
    const coordinateStateLng = _.get(item, ["coordinates", "longitude"], null);

    // const radiusCity = _.get(item, ["radius"], null);
    const coordinatelat = _.get(item, ["state", "coordinates", "latitude"], null);
    const coordinatelng = _.get(item, ["state", "coordinates", "longitude"], null);
    console.log(item)
    if (ID === null) {
      this.setState({
        center: {
          lat: coordinateStateLat,
          lng: coordinateStateLng
        },
        zoom: 10,
        radius: radius,
        isFirst: true
      });
    } else {
      this.setState({
        center: {
          lat: coordinatelat,
          lng: coordinatelng
        },
        zoom: 10,
        radius: radius,
        isFirst: true
      });
    }

    var pyrmont = new google.maps.LatLng(-33.8665433, 151.1956316);

    this.setState({ searchValue: name });
    this.onCloseAutoCompleted();
    this.startSearchPropertyLocal(name);
  };
  // For rent
  handleSelectLocalRent = (e, item) => {
    e.stopPropagation();
    const { debounceSearchTrigger } = this.state;
    const ID = _.get(item, ["id"], null);

    const name = _.get(item, ["name"], null);
    const radius = _.get(item, ["radius"], null);
    const coordinateStateLat = _.get(item, ["coordinates", "latitude"], null);
    const coordinateStateLng = _.get(item, ["coordinates", "longitude"], null);

    // const radiusCity = _.get(item, ["radius"], null);
    const coordinatelat = _.get(item, ["state", "coordinates", "latitude"], null);
    const coordinatelng = _.get(item, ["state", "coordinates", "longitude"], null);
    console.log(item)
    if (ID === null) {
      this.setState({
        center: {
          lat: coordinateStateLat,
          lng: coordinateStateLng
        },
        zoom: 10,
        radius: radius,
        isFirst: true
      });
    } else {
      this.setState({
        center: {
          lat: coordinatelat,
          lng: coordinatelng
        },
        zoom: 10,
        radius: radius,
        isFirst: true
      });
    }

    var pyrmont = new google.maps.LatLng(-33.8665433, 151.1956316);

    this.setState({ searchValue: name });
    this.onCloseAutoCompleted();
    this.startSearchPropertyLocalRent(name);
  };

  startSearchPropertyLocal = value => {
    const { postSearchVehicleQuery, searchFilterData, setFilterData, commonDataState } = this.props;
    const route = this.getRoute();
    if (_.isEmpty(value)) {
      setFilterData && setFilterData(route, _.unset(searchFilterData, "keyword"));
      this.setState({ isFirst: false });
      return;
    }

    const formatedValue = _.replace(_.toLower(value), /[\s,]/g, '');
    console.log("formatedValue", formatedValue)

    let matchedWords;

    const matchingItem = commonDataState.find(item => {
      const lowercasedName = _.replace(_.toLower(item.name), /[\s,]/g, '');
      console.log("lowercasedName", lowercasedName)
      return formatedValue.includes(lowercasedName);
    });

    console.log("matchingItem", matchingItem)

    const formattedValue = _.replace(_.toLower(value), /[\s,]/g, '-');

    Router.push({
      pathname: `/property-for-sale/${formattedValue}`,
    });

    setFilterData && setFilterData(route, {...searchFilterData, keyword: value});
  };
  // For rent
  startSearchPropertyLocalRent = value => {
    const { postSearchVehicleQuery, searchFilterData, setFilterData, commonDataState } = this.props;
    const route = this.getRoute();
    if (_.isEmpty(value)) {
      setFilterData && setFilterData(route, _.unset(searchFilterData, "keyword"));
      this.setState({ isFirst: false });
      return;
    }

    const formatedValue = _.replace(_.toLower(value), /[\s,]/g, '');
    console.log("formatedValue", formatedValue)

    let matchedWords;

    const matchingItem = commonDataState.find(item => {
      const lowercasedName = _.replace(_.toLower(item.name), /[\s,]/g, '');
      console.log("lowercasedName", lowercasedName)
      return formatedValue.includes(lowercasedName);
    });

    console.log("matchingItem", matchingItem)

    const formattedValue = _.replace(_.toLower(value), /[\s,]/g, '-');

    Router.push({
      pathname: `/property-for-rent/${formattedValue}`,
    });

    setFilterData && setFilterData(route, {...searchFilterData, keyword: value});
  };

  handleSelectLocalCity = (e, item) => {
    e.stopPropagation();
    const { debounceSearchTrigger } = this.state;
    const ID = _.get(item, ["id"], null);

    const name = _.get(item, ["name"], null);
    const nameState = _.get(item, ["state", "name"], null);
    const radius = _.get(item, ["radius"], null);

    const combinedName = nameState ? `${name}, ${nameState}` : name;

    console.log("checkdetail", item)
    console.log("nameState", nameState)
    console.log("combinedName", combinedName)

    let coordinatelat = _.get(item, ["coordinates", "latitude"], null);
    let coordinatelng = _.get(item, ["coordinates", "longitude"], null);

    if (coordinatelat === null || coordinatelng === null) {
      coordinatelat = _.get(item, ["state", "coordinates", "latitude"], null);
      coordinatelng = _.get(item, ["state", "coordinates", "longitude"], null);
    }

    console.log(coordinatelat, coordinatelng)

    this.setState({
      center: {
        lat: coordinatelat,
        lng: coordinatelng
      },
      zoom: 14,
      radius: radius,
      isFirst: true
    });

    this.setState({ searchValue: name });
    this.onCloseAutoCompleted();
    this.startSearchPropertyLocalCity(name, item);

  };
  //For rent
  handleSelectLocalCityRent = (e, item) => {
    e.stopPropagation();
    const { debounceSearchTrigger } = this.state;
    const ID = _.get(item, ["id"], null);

    const name = _.get(item, ["name"], null);
    const nameState = _.get(item, ["state", "name"], null);
    const radius = _.get(item, ["radius"], null);

    const combinedName = nameState ? `${name}, ${nameState}` : name;

    console.log("checkdetail", item)
    console.log("nameState", nameState)
    console.log("combinedName", combinedName)

    let coordinatelat = _.get(item, ["coordinates", "latitude"], null);
    let coordinatelng = _.get(item, ["coordinates", "longitude"], null);

    if (coordinatelat === null || coordinatelng === null) {
      coordinatelat = _.get(item, ["state", "coordinates", "latitude"], null);
      coordinatelng = _.get(item, ["state", "coordinates", "longitude"], null);
    }

    console.log(coordinatelat, coordinatelng)

    this.setState({
      center: {
        lat: coordinatelat,
        lng: coordinatelng
      },
      zoom: 14,
      radius: radius,
      isFirst: true
    });

    this.setState({ searchValue: name });
    this.onCloseAutoCompleted();
    this.startSearchPropertyLocalCityRent(name, item);

  };
  startSearchPropertyLocalCity = (value, item) => {
    const { postSearchVehicleQuery, searchFilterData, setFilterData, commonDataCity } = this.props;
    const route = this.getRoute();
    if (_.isEmpty(value)) {
      setFilterData && setFilterData(route, _.unset(searchFilterData, "keyword"));
      this.setState({ isFirst: false });
      return;
    }

    const nameState = _.get(item, ["state", "name"], null);
    const nameCity = _.get(item, ["name"], null);

    const formatedCity = _.replace(_.toLower(nameCity), /[\s,]/g, '');
    const formatedState = _.replace(_.toLower(nameState), /[\s,]/g, '');
    console.log("formatedCity", formatedCity)
    console.log("formatedState", formatedState)

    const matchingItem = commonDataCity.find(item => {
      const lowercasedName = _.replace(_.toLower(item.state.name), /[\s,]/g, '');
      const lowercasedNameCity = _.replace(_.toLower(item.name), /[\s,]/g, '');

      return formatedState.includes(lowercasedName) && formatedCity.includes(lowercasedNameCity);
    });

    console.log("matchingItem", matchingItem)

    const nameStateFormated = _.replace(_.toLower(nameState), /[\s,]/g, '-');
    const formattedValue = _.replace(_.toLower(value), /[\s,]/g, '-');

    Router.push({
      pathname: `/property-for-sale/${nameStateFormated}/${formattedValue}`,
    });

    setFilterData && setFilterData(route, {...searchFilterData, keyword: value});
  };
  //For rent
  startSearchPropertyLocalCityRent = (value, item) => {
    const { postSearchVehicleQuery, searchFilterData, setFilterData, commonDataCity } = this.props;
    const route = this.getRoute();
    if (_.isEmpty(value)) {
      setFilterData && setFilterData(route, _.unset(searchFilterData, "keyword"));
      this.setState({ isFirst: false });
      return;
    }

    const nameState = _.get(item, ["state", "name"], null);
    const nameCity = _.get(item, ["name"], null);

    const formatedCity = _.replace(_.toLower(nameCity), /[\s,]/g, '');
    const formatedState = _.replace(_.toLower(nameState), /[\s,]/g, '');
    console.log("formatedCity", formatedCity)
    console.log("formatedState", formatedState)

    const matchingItem = commonDataCity.find(item => {
      const lowercasedName = _.replace(_.toLower(item.state.name), /[\s,]/g, '');
      const lowercasedNameCity = _.replace(_.toLower(item.name), /[\s,]/g, '');

      return formatedState.includes(lowercasedName) && formatedCity.includes(lowercasedNameCity);
    });

    console.log("matchingItem", matchingItem)

    const nameStateFormated = _.replace(_.toLower(nameState), /[\s,]/g, '-');
    const formattedValue = _.replace(_.toLower(value), /[\s,]/g, '-');

    Router.push({
      pathname: `/property-for-rent/${nameStateFormated}/${formattedValue}`,
    });

    setFilterData && setFilterData(route, {...searchFilterData, keyword: value});
  };


  render() {
    const {
      buySearchText,
      rentSearchText,
      searchSuggestionItems,
      searchSuggestionLoading,
      searchRentSuggestionItems,
      gmapsLoaded,
      commonDataState,
      commonDataCity
    } = this.props;
    const { anchorEL, buyVisible, tab, rentVisible } = this.state;
    return (
      <HeaderImageStyle
        style={{ height: "20vh", marginTop: "2rem", zIndex: 999, maxHeight: "280px" }}
        responsive
        // image="/home-banner.png"
        // image="/images/living-room-interior.png"
        image="/images/main-top-banner.jpg"
      >
        <Container>
          <ContainerStyle>
            <GridContainer style={{ justifyContent: "center" }}>
              <GridItem style={{ width: "100%" }}>
                <BrandStyle>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                  {/*<TitleStyle*/}
                  {/*    variant="h1"*/}
                  {/*  style={{*/}
                  {/*    fontWeight: "600",*/}
                  {/*    fontSize: "1.3rem",*/}
                  {/*    textAlign: "center",*/}
                  {/*    marginTop: "13rem",*/}
                  {/*    width: "300px",*/}
                  {/*    textShadow: "0px 3px 5px rgba(0, 0, 0, 0.64)"*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  Find Your Next Home In Minutes.*/}
                  {/*</TitleStyle>*/}
                    <div style={{ visibility: "hidden", marginTop: "16rem" }}></div>
                  </div>
                  <Box
                    sx={{
                      width: "100%",
                      marginTop: "2rem",
                      marginBottom: "4rem"
                    }}
                  >
                    <Box sx={{ backgroundColor: 'transparent' }}>
                      <TabsUnstyled
                        onChange={this.onChangeTab}
                        defaultValue={0}
                        style={{ background: "rgba(0,0,0,.5)", padding: "20px 20px", borderRadius: "6px" }}
                      >
                        <TabsList>
                          <Tab>Buy</Tab>
                          <TabRight>Rent</TabRight>
                        </TabsList>
                        <TabPanel value={0} index={0}>
                          {gmapsLoaded && commonDataState ? (
                            <PlacesAutocomplete
                              value={buySearchText}
                              onChange={this.onChangeBuyText}
                              searchOptions={{
                                componentRestrictions: { country: "my" },
                                fields: ["geometry"],
                                types: ["geocode", "establishment"]
                              }}
                              // onSelect={this.handleSelect}
                              style={{ position: "relative" }}
                              ref={refObj => {
                                refObj ? (refObj.handleInputOnBlur = () => {}) : false;
                              }}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading
                              }) => (
                                <div
                                  style={{ position: "relative" }}
                                  onClick={this.onFocus}
                                >
                                  <TextFieldContainer
                                    hiddenLabel
                                    id="filled-hidden-label-normal"
                                    placeholder="Enter an address, city or ZIP code"
                                    variant="filled"
                                    disableunderline={"true"}
                                    autoComplete="off"
                                    onChange={this.onChangeBuyText}
                                    //  onBlur={this.handleClose}
                                    // fullWidth={200}
                                    value={buySearchText}
                                    inputProps={{
                                      style: { backgroundColor: "white" }
                                    }}
                                    {...getInputProps({
                                      placeholder: "Enter an address, city or ZIP code",
                                      className: "location-search-input"
                                    })}
                                    style={{
                                      backgroundColor: "white",
                                      width: "100%",
                                      borderRadius: buyVisible
                                        ? "4px 4px 0 0"
                                        : "4px 4px 4px 4px",
                                      padding: "3px"
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment
                                          style={{ backgroundColor: "white" }}
                                          position="end"
                                        >
                                          <Button
                                            onClick={this.onClickBuySearch}
                                            style={{
                                              backgroundColor:
                                                Color.primaryColor,
                                              color: "white",
                                              paddingLeft: "3px",
                                              paddingRight: "3px",
                                              borderRadius: "4px",
                                              minWidth: "45px"
                                            }}
                                            color="primary"
                                            variant="contained"
                                            aria-label="SearchIcon"
                                          >
                                            <SearchIcon />
                                          </Button>
                                        </InputAdornment>
                                      )
                                    }}
                                  />
                                  {buyVisible ? (
                                    <AutoCompleteContainer>
                                      {/*/!*===Local Data===*!/*/}
                                      {/*{!_.isEmpty(suggestions) ? (*/}
                                      {/*    <CustomFont styles={{ paddingLeft: "20px", color: Colors.primaryColor, fontWeight: "600" }}>Top Suggestions</CustomFont>*/}
                                      {/*) : (*/}
                                      {/*    false*/}
                                      {/*)}*/}
                                      {!_.isEmpty(suggestions) && (
                                          _.some([...commonDataState, ...commonDataCity], item => item.name.toLowerCase().includes(buySearchText.toLowerCase()))
                                      ) ? (
                                          <CustomFont styles={{ paddingLeft: "20px", color: Colors.primaryColor, fontWeight: "600" }}>Top Suggestions</CustomFont>
                                      ) : (
                                          false
                                      )}
                                      {!_.isEmpty(suggestions) ? (
                                          _.map(commonDataState.filter(item => item.name.toLowerCase().includes(buySearchText.toLowerCase())), (item, index) => {
                                            const nameState = _.get(item, "name", "");
                                            return (
                                                <SearchItemContainer
                                                    {...getSuggestionItemProps(item)}
                                                    onClick={e => this.handleSelectLocal(e, item)}
                                                >
                                                  <CustomFont hover>{nameState}</CustomFont>
                                                </SearchItemContainer>
                                            );
                                          })
                                      ) :  (
                                          false
                                      )}
                                      {!_.isEmpty(suggestions) ? (
                                          _.map(commonDataCity.filter(item => item.name.toLowerCase().includes(buySearchText.toLowerCase())).slice(0, 5), (item, index) => {
                                            const nameCity = _.get(item, "name", "");
                                            const nameState = _.get(item, ["state", "name"], "");
                                            return (
                                                <SearchItemContainer
                                                    {...getSuggestionItemProps(item)}
                                                    onClick={e => this.handleSelectLocalCity(e, item)}
                                                >
                                                  <CustomFont hover>{nameCity}, {nameState}</CustomFont>
                                                </SearchItemContainer>
                                            );
                                          })
                                      ) :  (
                                          false
                                      )}
                                      {/*// Google map*/}
                                      {!_.isEmpty(suggestions) ? (
                                          <CustomFont styles={{ paddingLeft: "20px", color: Colors.primaryColor, fontWeight: "600" }}>Additional Locations</CustomFont>
                                      ) : (
                                          false
                                      )}
                                      {!_.isEmpty(suggestions) ? (
                                        _.map(suggestions, item => {
                                          const description = _.get(
                                            item,
                                            "description",
                                            ""
                                          );
                                          return (
                                            <SearchItemContainer
                                              {...getSuggestionItemProps(item)}
                                              onClick={e =>
                                                this.onClickItem(e, item)
                                              }
                                            >
                                              <CustomFont hover>
                                                {description}
                                              </CustomFont>
                                            </SearchItemContainer>
                                          );
                                        })
                                      ) : _.isEmpty(suggestions) && !loading ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "200px"
                                          }}
                                        >
                                          <InfoIcon
                                            style={{ color: "#2c2c2c" }}
                                          />
                                          <CustomFont styles={{ marginTop: 5 }}>
                                            {_.isEmpty(buySearchText)
                                              ? `Start typing keyword`
                                              : `No related keyword`}
                                          </CustomFont>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "200px"
                                          }}
                                        >
                                          <LoadingOverlay />
                                        </div>
                                      )}
                                    </AutoCompleteContainer>
                                  ) : (
                                    false
                                  )}
                                </div>
                              )}
                            </PlacesAutocomplete>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                // padding: "0px 5px"
                                bgcolor: "white",
                                borderRadius: "3px"
                              }}
                            >
                              <Skeleton
                                variant="rounded"
                                width={300}
                                height={62}
                                animation="wave"
                                sx={{
                                  bgcolor: "white",
                                  flex: "1",
                                  color: Colors.iconColor,
                                  paddingLeft: "20px",
                                  textAlign: "left",
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "0.9rem",
                                  borderRadius: "3px"
                                }}
                              >
                                Loading.....
                              </Skeleton>

                              <Skeleton
                                variant="rounded"
                                height={36}
                                animation="wave"
                                sx={{
                                  bgcolor: Colors.primaryColor,
                                  flex: "0 0 45px",
                                  marginRight: "13px"
                                }}
                              />
                            </Box>
                          )}
                        </TabPanel>
                        <TabPanel value={1} index={1}>
                          {gmapsLoaded && commonDataState ? (
                            <PlacesAutocomplete
                              value={rentSearchText}
                              onChange={this.onChangeRentText}
                              searchOptions={{
                                componentRestrictions: { country: "my" },
                                fields: ["geometry"],
                                types: ["geocode", "establishment"]
                              }}
                              // onSelect={this.handleSelect}
                              style={{ position: "relative" }}
                              ref={refObj => {
                                refObj ? (refObj.handleInputOnBlur = () => {}) : false;
                              }}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading
                              }) => (
                                <div
                                  style={{ position: "relative" }}
                                  onClick={this.onRentFocus}
                                >
                                  <TextFieldContainer
                                    hiddenLabel
                                    id="filled-hidden-label-normal"
                                    placeholder="Enter an address, city or ZIP code"
                                    variant="filled"
                                    autoComplete="off"
                                    //onFocus={this.onRentFocus}
                                    onChange={this.onChangeRentText}
                                    // onBlur={this.handleRentClose}
                                    // fullWidth={200}
                                    value={rentSearchText}
                                    inputProps={{
                                      style: { backgroundColor: "white" }
                                    }}
                                    {...getInputProps({
                                      placeholder: "Enter an address, city or ZIP code",
                                      className: "location-search-input"
                                    })}
                                    style={{
                                      backgroundColor: "white",
                                      width: "100%",
                                      borderRadius: rentVisible
                                          ? "4px 4px 0 0"
                                          : "4px 4px 4px 4px",
                                      padding: "3px"
                                      // paddingTop: "25px",
                                      // paddingBottom: "25px"
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment
                                          style={{ backgroundColor: "white" }}
                                          position="end"
                                        >
                                          <Button
                                            onClick={this.onClickRentSearch}
                                            variant="contained"
                                            aria-label="SearchIcon"
                                            style={{
                                              backgroundColor:
                                              Color.primaryColor,
                                              color: "white",
                                              paddingLeft: "3px",
                                              paddingRight: "3px",
                                              borderRadius: "4px",
                                              minWidth: "45px"
                                            }}
                                          >
                                            <SearchIcon />
                                          </Button>
                                        </InputAdornment>
                                      ),
                                      classes: TextFieldContainer
                                    }}
                                  />
                                  {rentVisible ? (
                                    <AutoCompleteContainer>
                                      {/*/!*===Local Data===*!/*/}
                                      {/*{!_.isEmpty(suggestions) ? (*/}
                                      {/*    <CustomFont styles={{ paddingLeft: "20px", color: Colors.primaryColor, fontWeight: "600" }}>Top Suggestions</CustomFont>*/}
                                      {/*) : (*/}
                                      {/*    false*/}
                                      {/*)}*/}
                                      {!_.isEmpty(suggestions) && (
                                          _.some([...commonDataState, ...commonDataCity], item => item.name.toLowerCase().includes(rentSearchText.toLowerCase()))
                                      ) ? (
                                          <CustomFont styles={{ paddingLeft: "20px", color: Colors.primaryColor, fontWeight: "600" }}>Top Suggestions</CustomFont>
                                      ) : (
                                          false
                                      )}
                                      {!_.isEmpty(suggestions) ? (
                                          _.map(commonDataState.filter(item => item.name.toLowerCase().includes(rentSearchText.toLowerCase())), (item, index) => {
                                            const nameState = _.get(item, "name", "");
                                            return (
                                                <SearchItemContainer
                                                    {...getSuggestionItemProps(item)}
                                                    onClick={e => this.handleSelectLocalRent(e, item)}
                                                >
                                                  <CustomFont hover>{nameState}</CustomFont>
                                                </SearchItemContainer>
                                            );
                                          })
                                      ) :  (
                                          false
                                      )}
                                      {!_.isEmpty(suggestions) ? (
                                          _.map(commonDataCity.filter(item => item.name.toLowerCase().includes(rentSearchText.toLowerCase())).slice(0, 5), (item, index) => {
                                            const nameCity = _.get(item, "name", "");
                                            const nameState = _.get(item, ["state", "name"], "");
                                            return (
                                                <SearchItemContainer
                                                    {...getSuggestionItemProps(item)}
                                                    onClick={e => this.handleSelectLocalCityRent(e, item)}
                                                >
                                                  <CustomFont hover>{nameCity}, {nameState}</CustomFont>
                                                </SearchItemContainer>
                                            );
                                          })
                                      ) :  (
                                          false
                                      )}
                                      {/*// Google map*/}
                                      {!_.isEmpty(suggestions) ? (
                                          <CustomFont styles={{ paddingLeft: "20px", color: Colors.primaryColor, fontWeight: "600" }}>Additional Locations</CustomFont>
                                      ) : (
                                          false
                                      )}
                                      {!_.isEmpty(suggestions) ? (
                                        _.map(suggestions, item => {
                                          const description = _.get(
                                            item,
                                            "description",
                                            ""
                                          );
                                          return (
                                            <SearchItemContainer
                                              {...getSuggestionItemProps(item)}
                                              onClick={e =>
                                                this.onClickRentItem(e, item)
                                              }
                                            >
                                              <CustomFont hover>
                                                {description}
                                              </CustomFont>
                                            </SearchItemContainer>
                                          );
                                        })
                                      ) : _.isEmpty(suggestions) && !loading ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "200px"
                                          }}
                                        >
                                          <InfoIcon
                                            style={{ color: "#2c2c2c" }}
                                          />
                                          <CustomFont styles={{ marginTop: 5 }}>
                                            {_.isEmpty(rentSearchText)
                                              ? `Start typing keyword`
                                              : `No related keyword`}
                                          </CustomFont>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            display: "flex",
                                            flex: 1,
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "200px"
                                          }}
                                        >
                                          <LoadingOverlay />
                                        </div>
                                      )}
                                    </AutoCompleteContainer>
                                  ) : (
                                    false
                                  )}
                                </div>
                              )}
                            </PlacesAutocomplete>
                          ) : (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                // padding: "0px 5px"
                                bgcolor: "white",
                                borderRadius: "3px"
                              }}
                            >
                              <Skeleton
                                variant="rounded"
                                width={300}
                                height={62}
                                animation="wave"
                                sx={{
                                  bgcolor: "white",
                                  flex: "1",
                                  color: Colors.iconColor,
                                  paddingLeft: "20px",
                                  textAlign: "left",
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "0.9rem",
                                  borderRadius: "3px"
                                }}
                              >
                                Loading.....
                              </Skeleton>

                              <Skeleton
                                variant="rounded"
                                height={36}
                                animation="wave"
                                sx={{
                                  bgcolor: Colors.primaryColor,
                                  flex: "0 0 45px",
                                  marginRight: "13px"
                                }}
                              />
                            </Box>
                          )}
                        </TabPanel>
                      </TabsUnstyled>
                    </Box>
                  </Box>
                </BrandStyle>
              </GridItem>
            </GridContainer>
          </ContainerStyle>
        </Container>
      </HeaderImageStyle>
    );
  }
}

export default withTheme(MobileBannerContainer);

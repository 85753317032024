export const FontSize = {
    xsmall: "0.8rem",
    small: "0.9rem",
    normal: "1rem",
    large: "1.2rem",
    tlarge: "1.3rem",
    xlarge: "1.5rem",
    H1: "1.875rem",
    xalarge: "2rem",
    xxlarge: "2.2rem",
    xxxlarge: "3.0rem"
};
